
.swiper-pagination-vertical.swiper-pagination-bullets{
  /* width: 3.541; */
  top: 0;
  right: 0%;
  transform: translate3d(0px, 0, 0);
  
}
.swiper-pagination{
  
  @media only screen and (max-width: 768px) {
    display:none
  }
}
.swiper-pagination-bullet {
  width: 100%;
  height: 100px;
  background: none;
  /* margin: 0 !important; */
  @media only screen and (max-width: 1440px) {
    height: 80px;
  };
  @media only screen and (max-width: 1024px) {
    height: 60px;
  };
}
.swiper-pagination-bullet:nth-last-child(1){
  display: none !important; 
}
.swiper-pagination-bullet > img {
  width: 100%;
  height: 100%;
  /* object-fit:fill; */
  object-fit: contain;
}
/* .swiper-slide{
  height: 100% !important;
} */

.swiper-scrollbar {
  top:0 !important;
  left: var(--swiper-scrollbar-left, auto) !important;
  right: 11% !important;
  width: var(--swiper-scrollbar-size, 1.5px) !important;
}

.swiper-scrollbar-drag {
  height: 3.57px;
  border-radius: 0;
  top: -3.57px;
}