html::-webkit-scrollbar {
  width: 0;
}
body::-webkit-scrollbar {
  width: 0;
}
#root::-webkit-scrollbar {
  width: 0;
}

.App {
  text-align: center;
  max-width: 100vw;
  min-width: 100vw;
  font-family:'Pretendard Variable' !important;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
}
.App::-webkit-scrollbar {
  width: 0;
}
.div{
  font-family: 'Pretendard Variable';
}
.span{
  font-family: 'Pretendard Variable';
}
.p{
  font-family: 'Pretendard Variable';
}
.a{
  font-family: 'Pretendard Variable';
}